@import "/node_modules/toastify-js/src/toastify.css";
@import "~@fortawesome/fontawesome-pro/css/all";
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
body, html{
    background-color: #fff;
    overflow-x: hidden;
    width: 100%;
}
@media screen and (max-width: 768px) {
    body{
        position: relative;
    }
}
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 

input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* scroll-bar width */
::-webkit-scrollbar,
.scrollable::-webkit-scrollbar {
    width: 4px;
}
.scrollable{
    overflow-y: scroll;
}
/* scroll-bar Track */
::-webkit-scrollbar-track,
.scrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* scroll-bar Handle */
::-webkit-scrollbar-thumb,
.scrollable::-webkit-scrollbar-thumb {
    background: #888;
}

/* scroll-bar Handle on hover */
::-webkit-scrollbar-thumb:hover,
.scrollable::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
}
#layout-app{
    min-height: 100vh;
}
#ridder-feet{
    background-color: #E6E6E6;
    color: #000;
    padding: 3px 0;
    text-decoration: none;
    cursor: pointer;
    transition: .3s;
    font-size: 12px;
    font-weight: 500;
    &:hover,
    &:focus{
        background-color: #CCC;
    }
}
.btn{
    border-radius: 0% !important;
}
.btn-primary{
    color: $white;
    &:hover,
    &:focus{
        background-color: $secondary;
        border: 1px solid $secondary;
    }
}
.btn-success{
    color: $white;
}
.input-group{
    border-radius: 2px;
    &.password-input{
        input{
            border-right: none;
        }
        .input-group-append{
            border-radius: 2px;
            border: 1px solid #ced4da;
            font-size: 1.2em;
            color: #444;
    
        }
    }
    
}
i{
    cursor: pointer;
    &:hover,
    &:focus{
        color: $secondary;
    }
}
.general-padding {
    width: 100%;
    padding: 0 3vw;
}
.general-body-padding{
    width: 100%;
    padding: 0 10vw;
}
.custom-space {
    letter-spacing: 1.68px;
}
.image-input-list{
    margin: 1vh 0 0 0;
    padding: 0;
    list-style: none;
    .image-input-list-item{
        margin: 1vh 5px;
        display: inline-block;
        vertical-align: middle;
        width: min-content;
        
    }
}

.image-container{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px dotted $primary;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s;
    &:hover,
    &:focus,
    &:hover .image-title,
    &:focus .image-title{
        color: $secondary;
    }
}

.image-title{
    font-size: .9em;
    color: $disabled;
    text-align: center;
    transition: .3s;
}
.image-input{
    display: none;
}
.scrollable-transparent::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar-thumb
{
    background-color: #ffffff;
}
.Scrollable, .Scrollable2, .scrollable-transparent{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .fa{
    transition: .3s;
    &:hover{
        transform: scale(1.05);
    }
  }
  .pagination{
    li{
        cursor: pointer;
    }
  }
//center div
.centered{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dropdown{
    .dropdown-btn{
        padding-top: ($spacer * .5) !important;
        padding-bottom: ($spacer * .5) !important;
        padding-left: $spacer !important;
        padding-right: $spacer !important;
        border-radius: 0pt;
        border: 1px solid $border;
        background-color: $white;
        text-align: left;
        .dropdown-btn-input{
            border: none;
            width: fit-content;
            
        }
        .dropdown-btn-text{
            width: 95%;
            text-overflow: ellipsis;
            overflow: hidden;
            
        }
    }
    .dropdown-menu{
        width: 96% !important;
        .dropdown-item{
            cursor: pointer;
            letter-spacing: 0.5pt;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0 auto;
        }
    }
    
}
.button-border{
    border-bottom: 1px solid $border;
}

.payment-status-0{
    //color orange
    color: #ff9800;
}
.payment-status-1{
    //color green
    color: #4caf50;
}
.payment-status-2{
    //color red
    color: #f44336;
}
.process-status-0{
    //color orange
    color: #ff9800;
}
.process-status-1{
    //color yellow
    color: #ffc107;
}
.process-status-2{
    //color blue
    color: #2196f3;
}
.process-status-3{
    //color red
    color: #f44336;
}
.process-status-4{
    //color purple
    color: #9c27b0;
}
.process-status-5{
    //color green
    color: #4caf50;
}
.fa-chevron-up,
.fa-chevron-down{
    height: 20px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 768px) {
    #promotion-container{
        width: 100%;
        h3 {
            margin:30px 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: #1d1d1d;
        }
        #promotion-list{
            margin: 0 0 5vh 0;
            padding: 0;
            list-style: none;
            .promotion-item{
                margin: 0;
                padding: 4vh 1%;
                width: 48%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                cursor: pointer;
                &:hover .promotion-item-discount{
                    transform: scale(1.2);
                }
                &:hover .promotion-item-img{
                    transform: scale(.99);
                }
                .promotion-item-img{
                    width: 16vw;
                    height: 20vw;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: .3s;
                }
                .promotion-item-text-container{
                    width: 27vw;
                    .promotion-text{
                        color: $gray-secondary;
                        width: 100%;
                    }
                    .promotion-item-title{
                        font-size: 2.2em;
                        font-weight: 600;
                        margin: 0 0 10px 0;
                        padding: 0;
                    }
                    .promotion-item-text{
                        font-size: 1.1em;
                        font-weight: 300;
                        text-align: left;
                        margin: 0;
                        padding: 0;
                    }
                    .promotion-item-discount{
                        font-size: 4em;
                        text-align: center;
                        font-weight: 800;
                        margin: 6vh 0 0 0;
                        padding: 0;
                        transition: .3s;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #promotion-container{
        width: 100%;
        h3 {
            margin:30px 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: #1d1d1d;
        }
        #promotion-list{
            margin: 0 0 5vh 0;
            padding: 0;
            list-style: none;
            .promotion-item{
                margin: 0 auto 2vh;
                padding: 2vh 2%;
                width: 95%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                cursor: pointer;
                &:hover .promotion-item-discount{
                    transform: scale(1.2);
                }
                &:hover .promotion-item-img{
                    transform: scale(.99);
                }
                .promotion-item-img{
                    width: 45vw;
                    height: 75vw;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: .3s;
                }
                .promotion-item-text-container{
                    width: 40vw;
                    .promotion-text{
                        color: $gray-secondary;
                        width: 100%;
                    }
                    .promotion-item-title{
                        font-size: 1.6em;
                        font-weight: 600;
                        margin: 0 0 10px 0;
                        padding: 0;
                    }
                    .promotion-item-text{
                        font-size: 1em;
                        font-weight: 300;
                        text-align: left;
                        margin: 0;
                        padding: 0;
                    }
                    .promotion-item-discount{
                        font-size: 3.5em;
                        text-align: center;
                        font-weight: 800;
                        margin: 4vh 0 0 0;
                        padding: 0;
                        transition: .3s;
                    }
                }
            }
        }
    }
}
.pagination{
    .page-item{
        cursor: pointer;
    }
    .page-link{
        select{
            border: none;
            cursor: pointer;
            //remove border on hover
            &:hover, &:active, &:focus{
                border: none;
            }
        }
    }
}